export default {

    CHAIN_PARAMS: {
        chainId: 42161,
        chainIdHex: "0xA4B1",
        chainName: "Arbitrum",
        nativeCurrency: { name: 'Wrapped Ethereum', symbol: 'WETH', decimals: 18, logo: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Feth.png&w=64&q=100' },
        wrappedNativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18, address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1", logo: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Feth.png&w=64&q=100' },
        rpcURL: "https://arb1.arbitrum.io/rpc",
        blockExplorerURL: "https://arbiscan.io",
    },

    // Token addresses should be in lowercase
    DEFAULT_TOKEN_LIST: {
        // Tokens, which would be displayed on the top of Token Selector Modal
        defaultTokens: {
            ["0x82af49447d8a07e3bd95bd0d56f35241523fbab1"]: { name: "Wrapped Ethereum", symbol: "WETH", decimals: 18 },
            ["0xff970a61a04b1ca14834a43f5de4533ebddb5cc8"]: { name: "USDC", symbol: "USDC", decimals: 6 },
            ["0x3b475f6f2f41853706afc9fa6a6b8c5df1a2724c"]: { name: "Zyberswap Token", symbol: "ZYBER", decimals: 18 },
            ["0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9"]: { name: "USDT", symbol: "USDT", decimals: 6 },
            ["0xda10009cbd5d07dd0cecc66161fc93d7c9000da1"]: { name: "DAI", symbol: "DAI", decimals: 18 },
            ["0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f"]: { name: "Wrapped BTC", symbol: "WBTC", decimals: 8 },
            ["0x912ce59144191c1204e64559fe8253a0e49e6548"]: { name: "ARB", symbol: "ARB", decimals: 18 },
        },
        // Tokens, which would be used for creating multihop routes
        tokensForMultihop: {
            ["0x82af49447d8a07e3bd95bd0d56f35241523fbab1"]: { name: "Wrapped Ethereum", symbol: "WETH", decimals: 18 },
            ["0xff970a61a04b1ca14834a43f5de4533ebddb5cc8"]: { name: "USDC", symbol: "USDC", decimals: 6 },
        },
        tokensLogos: {
            ["0x82af49447d8a07e3bd95bd0d56f35241523fbab1"]: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Feth.png&w=64&q=100',
            ["0xff970a61a04b1ca14834a43f5de4533ebddb5cc8"]: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Fusdc.png&w=48&q=100',
            ["0x3b475f6f2f41853706afc9fa6a6b8c5df1a2724c"]: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Fzyber.png&w=48&q=100',
            ["0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9"]: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Fusdt.png&w=64&q=100',
            ["0xda10009cbd5d07dd0cecc66161fc93d7c9000da1"]: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Fdai.png&w=64&q=100',
            ["0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f"]: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Fwbtc.png&w=64&q=100',
            ["0x912ce59144191c1204e64559fe8253a0e49e6548"]: 'https://app.zyberswap.io/_next/image?url=%2Fimages%2Ftokens%2Farb.png&w=64&q=100'
        },
        stableTokens: {
            ["0xff970a61a04b1ca14834a43f5de4533ebddb5cc8"]: { name: "USDC", symbol: "USDC", decimals: 6 },
            ["0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9"]: { name: "USDT", symbol: "USDT", decimals: 6 },
        },
        stableTokenForUSDPrice: { address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8", name: "USDC", symbol: "USDC", decimals: 6 },
        // Real tokens and their possible fake names. Used for token launch safety
        filterForScamTokens: {
            tokensForCheck: {
                ["DD"]: "0x582daef1f36d6009f64b74519cfd612a8467be18",
                ["DC"]: "0x7b4328c127b85369d9f82ca0503b000d09cf9180"
            },
            possibleFakeNames: [
                {
                    names: ['Doge Dragon', 'DogeDragon', 'Dragon Doge', 'DragonDoge'],
                    realAddress: "0x582daef1f36d6009f64b74519cfd612a8467be18"
                },
                {
                    names: ['Dogechain Token', 'DogeChain Token', 'Dogechain', 'DogeChain'],
                    realAddresses: "0x7b4328c127b85369d9f82ca0503b000d09cf9180"
                }
            ]
        }
    },

    V3_CONTRACTS: {
        POOL_DEPLOYER_ADDRESS: "0x24E85F5F94C6017d2d87b434394e87df4e4D56E3",
        FACTORY_ADDRESS: "0x9C2ABD632771b433E5E7507BcaA41cA3b25D8544",
        QUOTER_ADDRESS: "0xAeD211346Fa2E6A5063b4f273BCf7DDbD0368d62",
        SWAP_ROUTER_ADDRESS: "0xFa58b8024B49836772180f2Df902f231ba712F72",
        NONFUNGIBLE_POSITION_MANAGER_ADDRESS: "0xCB0347cc2c07f827Fdd49eD86Ee867A754b647DC",
        MULTICALL_ADDRESS: "0xc29DCff5A4eFB8829789969adDdf66345000f313",
        MIGRATOR_ADDRESS: "0xcd8afFd146D67DdD3712aEf394AE7F1f3041709E",
        FARMING_CENTER_ADDRESS: "0xa2Adf5EF43226a4a6A7c6b360F486bDaf2712Bc8",
        LIMIT_FARMING_ADDRESS: "0x67Db143dcfeEB9b1BfE7fdA64bb7b23c844Bc2Aa",
        ETERNAL_FARMING_ADDRESS: "0x40F5AF7171b35963DBA9Cd9952d3f11F5e32Dd30",
        POOL_INIT_CODE_HASH: "0x6ec6c9c8091d160c0aa74b2b14ba9c1717e95093bd3ac085cee99a49aab294a4"
    },

    SUBGRAPH: {
        infoURL: "https://api.thegraph.com/subgraphs/name/iliaazhel/zyberswap-info",
        farmingURL: "https://api.thegraph.com/subgraphs/name/iliaazhel/zyberswap-farming",
        blocklyticsURL: "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks"
    },

    API: {
        eternalFarmsAPR: "https://apiv3.zyberswap.io/api/APR/eternalFarmings/?network=zyberswap_arbitrum",
        limitFarmsAPR: "https://apiv3.zyberswap.io/api/APR/limitFarmings/?network=zyberswap_arbitrum",
        eternalFarmsTVL: "https://apiv3.zyberswap.io/api/TVL/eternalFarmings/?network=zyberswap_arbitrum",
        limitFarmsTVL: "https://apiv3.zyberswap.io/api/TVL/limitFarmings/?network=zyberswap_arbitrum",
        poolsAPR: "https://apiv3.zyberswap.io/api/APR/pools/?network=zyberswap_arbitrum"
    },

    MISC: {
        title: "ZyberSwap",
        description: "Swap and provide liquidity on ZyberSwap",
        appURL: "https://v3.zyberswap.io"
    }
}
