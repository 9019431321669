import { Trans, t } from '@lingui/macro'
import SettingsTab from '../Settings'
import { Percent } from '@uniswap/sdk-core'
import { useState } from 'react';
import { ChevronDown } from 'react-feather';

interface SwapHeaderProps {
    allowedSlippage: Percent
    dynamicFee: number | null
}

export default function SwapHeader({ allowedSlippage, dynamicFee = null }: SwapHeaderProps) {

    const [dropdownOpen, setDropdownOpen] = useState(false);


    return (
        <div className={'flex-s-between w-100 mb-1'}>
            <div className={'flex-s-between w-100'}>
                <div onClick={() => {setDropdownOpen(!dropdownOpen)}} className={'mr-05 b fs-100 dropdown relative'}>
                    <div>Market (V3)</div>
                    <ChevronDown className='' />
                    {dropdownOpen && (
                        <>
                            <a href="https://app.zyberswap.io" rel="noreferrer" className='dropdown-open p-1'>
                                <div>Market (V2)</div>
                                <div className='subtitle'>Switch back to V2</div>
                            </a>  
                        </>
                    )}
                </div>
                {dynamicFee &&
                    <span className={'bg-p pv-025 ph-05 br-8'}>
                    <Trans>{`Fee is ${dynamicFee / 10000}%`}</Trans>
                    </span>
                }
            </div>
            <div onClick={() => {setDropdownOpen(false)}}>
                <SettingsTab placeholderSlippage={allowedSlippage} />
            </div>
        </div>
    )
}
