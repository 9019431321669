import React, { useMemo } from "react";
import { Text, TextProps as TextPropsOriginal } from "rebass";
import styled, { createGlobalStyle, css, DefaultTheme, ThemeProvider as StyledComponentsThemeProvider } from "styled-components/macro";
import { useIsDarkMode } from "../state/user/hooks";
import { Colors } from "./styled";

export * from "./components";

type TextProps = Omit<TextPropsOriginal, "css">;

export const MEDIA_WIDTHS = {
    upToExtraSmall: 500,
    upToSmall: 720,
    upToMedium: 960,
    upToLarge: 1280,
};

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
    (accumulator as any)[size] = (a: any, b: any, c: any) => css`
        @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
            ${css(a, b, c)}
        }
    `;
    return accumulator;
}, {}) as any;

const white = "#FFFFFF";
const black = "#000000";

function colors(darkMode: boolean): Colors {
    return {
        // base
        white,
        black,

        // text
        text1: darkMode ? "#FFFFFF" : "#000000",
        text2: darkMode ? "#FFFFFF" : "#565A69",
        text3: darkMode ? "#8F96AC" : "#6E727D",
        text4: darkMode ? "#B2B9D2" : "#C3C5CB",
        text5: darkMode ? "#0d0a25" : "#EDEEF2",

        // backgrounds / greys
        bg0: darkMode ? "rgba(0, 0, 0)" : "#FFF",
        bg1: darkMode ? "#1E292F" : "#F7F8FA",
        bg2: darkMode ? "#0d0a25" : "#EDEEF2",
        bg3: darkMode ? "#0d0a25" : "#CED0D9",
        bg4: darkMode ? "#565A69" : "#888D9B",
        bg5: darkMode ? "#6C7284" : "#888D9B",
        bg6: darkMode ? "#1A2028" : "#6C7284",

        //specialty colors
        modalBG: darkMode ? "rgba(0,0,0,.425)" : "rgba(0,0,0,0.8)",
        advancedBG: darkMode ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0.7)",

        //primary colors
        primary1: darkMode ? "#5d32ed" : "#E8006F",
        primary2: darkMode ? "#3680E7" : "#FF8CC3",
        primary3: darkMode ? "#4D8FEA" : "#FF99C9",
        primary4: darkMode ? "#376bad70" : "#F6DDE8",
        primary5: darkMode ? "#400f29" : "#FDEAF1",

        // color text
        primaryText1: darkMode ? "#ed1185" : "#D50066",

        // secondary colors
        secondary1: darkMode ? "#5d32ed" : "#E8006F",
        secondary2: darkMode ? "#17000b26" : "#F6DDE8",
        secondary3: darkMode ? "#17000b26" : "#FDEAF1",

        // other
        red1: darkMode ? "#FF4343" : "#DA2D2B",
        red2: darkMode ? "#F82D3A" : "#DF1F38",
        red3: "#D60000",
        green1: darkMode ? "#27AE60" : "#007D35",
        yellow1: "#E3A507",
        yellow2: "#FF8F00",
        yellow3: "#F3B71E",
        blue1: darkMode ? "#2172E5" : "#0068FC",
        blue2: darkMode ? "#5199FF" : "#0068FC",
        error: darkMode ? "#FD4040" : "#DF1F38",
        success: darkMode ? "#27AE60" : "#007D35",
        warning: "#FF8F00",

        // dont wanna forget these blue yet
        blue4: darkMode ? "#400f29" : "#C4D9F8",
        // blue5: darkMode ? '#153d6f70' : '#EBF4FF',

        winterBackground: "rgba(91, 183, 255, 0.7)",
        winterMainButton: "#02734f",
        winterDisabledButton: "#1e5e49",
    };
}

function theme(darkMode: boolean): DefaultTheme {
    return {
        ...colors(darkMode),

        grids: {
            sm: 8,
            md: 12,
            lg: 24,
        },

        //shadows
        shadow1: darkMode ? "#000" : "#2F80ED",

        // media queries

        mediaWidth: mediaWidthTemplates,

        // css snippets
        flexColumnNoWrap: css`
            display: flex;
            flex-flow: column nowrap;
        `,
        flexRowNoWrap: css`
            display: flex;
            flex-flow: row nowrap;
        `,
    };
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
    const darkMode = useIsDarkMode();

    const themeObject = useMemo(() => theme(darkMode), [darkMode]);

    return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
    // color: ${({ color, theme }) => (theme as any)[color]};
    color: white;
`;

export const TYPE = {
    main(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"text2"} {...props} />;
    },
    link(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"primary1"} {...props} />;
    },
    label(props: TextProps) {
        return <TextWrapper fontWeight={600} color={"text1"} {...props} />;
    },
    black(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"text1"} {...props} />;
    },
    white(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"white"} {...props} />;
    },
    body(props: TextProps) {
        return <TextWrapper fontWeight={400} fontSize={16} color={"text1"} {...props} />;
    },
    largeHeader(props: TextProps) {
        return <TextWrapper fontWeight={600} fontSize={24} {...props} />;
    },
    mediumHeader(props: TextProps) {
        return <TextWrapper fontWeight={500} fontSize={20} {...props} />;
    },
    subHeader(props: TextProps) {
        return <TextWrapper fontWeight={400} fontSize={14} {...props} />;
    },
    small(props: TextProps) {
        return <TextWrapper fontWeight={500} fontSize={11} {...props} />;
    },
    blue(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"blue1"} {...props} />;
    },
    yellow(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"yellow3"} {...props} />;
    },
    darkGray(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"text3"} {...props} />;
    },
    gray(props: TextProps) {
        return <TextWrapper fontWeight={500} color={"bg3"} {...props} />;
    },
    italic(props: TextProps) {
        return <TextWrapper fontWeight={500} fontSize={12} fontStyle={"italic"} color={"text2"} {...props} />;
    },
    error({ error, ...props }: { error: boolean } & TextProps) {
        return <TextWrapper fontWeight={500} color={error ? "red1" : "text2"} {...props} />;
    },
};

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
}

a {
 color: ${({ theme }) => theme.blue1};
}
`;
