import { useCallback } from "react";
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";

export default function ParticlesBackground() {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {}, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#101216",
                    },
                },
                fpsLimit: 120,
                particles: {
                    number: {
                        value: 10,
                        density: {
                            enable: !0,
                            value_area: 1000,
                        },
                    },
                    color: {
                        value: ["#29A19C"],
                    },
                    shape: {
                        type: "circle",
                        stroke: {
                            width: 5,
                            color: "#29A19C",
                        },
                        polygon: {
                            nb_sides: 5,
                        },
                        image: {
                            src: "img/github.svg",
                            width: 100,
                            height: 100,
                        },
                    },
                    opacity: {
                        value: 0.6,
                        random: !1,
                        anim: {
                            enable: !1,
                            speed: 0.3,
                            opacity_min: 0.1,
                            sync: !1,
                        },
                    },
                    size: {
                        value: 2,
                        random: !0,
                        anim: {
                            enable: !1,
                            speed: 20,
                            size_min: 0.1,
                            sync: !1,
                        },
                    },
                    line_linked: {
                        enable: !0,
                        distance: 500,
                        color: "#29A19C",
                        opacity: 0.5,
                        width: 1.6,
                    },
                    move: {
                        enable: !0,
                        speed: 1,
                        direction: "top",
                        random: !1,
                        straight: !1,
                        out_mode: "out",
                        bounce: !1,
                        attract: {
                            enable: !1,
                            rotateX: 600,
                            rotateY: 1200,
                        },
                    },
                },
                interactivity: {
                    detect_on: "canvas",
                    events: {
                        onhover: {
                            enable: !0,
                            mode: "grab",
                        },
                        onclick: {
                            enable: !1,
                        },
                        resize: !0,
                    },
                    modes: {
                        grab: {
                            distance: 300,
                            line_linked: {
                                opacity: 1,
                            },
                        },
                        bubble: {
                            distance: 400,
                            size: 40,
                            duration: 2,
                            opacity: 8,
                            speed: 3,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                        push: {
                            particles_nb: 4,
                        },
                        remove: {
                            particles_nb: 2,
                        },
                    },
                },
                detectRetina: !0,
            }}
        />
    );
}
